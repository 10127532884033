import React, { useCallback } from "react";
import { Button, Form, Input, Radio, Space, notification, Flex } from "antd";


const ContactUs = ({ setOpen }) => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const { TextArea } = Input;

  // const [items, setItems] = useState(["Cloud (AWS, Azure, GCP)", "Web (React, Django, etc.)", "Database (Snowflake, Postgres, etc.)", "CRM (Salesforce, HubSpot, etc.)", "Visualization (Tableau, Sigma, etc.)", "Lets just have some fun!"]);
  // const [name, setName] = useState('');
  // const inputRef = useRef<InputRef>(null);

    //for text inputs
  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log('Change:', e.target.value);
  };

  //https://plainenglish.io/blog/embedding-google-forms-in-react-apps

  const onFinish = useCallback(
    async ({ email, full_name, brief_intro, related_tech }) => {
      console.log(`related_tech: ${related_tech}`)
      try {
        await fetch(
          "https://docs.google.com/forms/d/e/1FAIpQLSeOG49WLqSmmV-SHz1_5bPH87IaAIucmGOewSPftnrb3fbfLA/formResponse?" +
            new URLSearchParams({
              "entry.294267638": full_name ?? "",
              "entry.1389825886": brief_intro ?? "",
              "entry.536507186": related_tech ?? "",
              emailAddress: email,
            }),
          {
            mode: "no-cors",
          }
        );
        api.success({
          message: "Submitted successfully, I will reach out shortly!",
        });
        form.resetFields();
        setOpen(false);
      } catch (e) {
        api.error({
          message: e.message,
        });
      }
    },
    [api, form, setOpen]
  );

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        wrapperCol={{ span: 18 }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Email address is required" }]}
        >
          <Input />
        </Form.Item>


        <Form.Item
          name="full_name"
          label="What is your name?"
          rules={[{ required: true, message: "React version is required" }]}
        >
          <Input showCount maxLength={100} onChange={onChange} placeholder="Art Vandelay..." />
        </Form.Item>
        <Form.Item
          name="brief_intro"
          label="Tell me about what you are interested in..."
          rules={[{ required: false, message: "React version is required" }]}
        >
        <Flex vertical gap={32}>
          <TextArea showCount maxLength={100} onChange={onChange} placeholder="Please, explore the space..." style={{ height: 120 }}/>
        </Flex>

        </Form.Item>


        <Form.Item
          name="related_tech"
          label="Related Tech (if applicable)"
        >
        <Radio.Group>
          <Space direction="vertical">
            {/*   const [items, setItems] = useState(["", "", "", "", "", ""]); */}
            <Radio value="Cloud">Cloud (AWS, Azure, GCP)</Radio>
            <Radio value="Web">Web (React, Django, etc.)</Radio>
            <Radio value="Database">Database (Snowflake, Postgres, etc.)</Radio>
            <Radio value="CRM">CRM (Salesforce, HubSpot, etc.)</Radio>
            <Radio value="Visualization">Visualization (Tableau, Sigma, etc.)</Radio>
            <Radio value="Fun">Lets just have some fun!</Radio>
            
          </Space>
        </Radio.Group>
        </Form.Item>


        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </>
  );
};

export default ContactUs;