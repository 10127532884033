// App.tsx
import React, { useState, useEffect  } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../pages/styles.css';

import GoodreadsWidget from '../components/goodreads.tsx';
import YelpReview from '../components/yelp_review.tsx';

const App: React.FC = () => {
  const [letterboxdData, setLetterboxdData] = useState<string>('Loading...');

  useEffect(() => {
      fetch('https://lb-embed-content.bokonon.dev?username=seanpjk')
          .then(response => response.text())
          .then(data => {
              setLetterboxdData(data);
          })
          .catch(error => {
              console.error('Error fetching Letterboxd data:', error);
              setLetterboxdData('Failed to load data');
          });
  }, []);

  useEffect(() => {
    try {
      const script = document.createElement('script');
      script.src = "https://www.yelp.com/embed/widgets.js";
      //script.async = true;
      //script.onerror = () => console.error('Error loading Yelp script.');
      document.body.appendChild(script);
    } catch (error) {
      console.error('Error during script insertion:', error);
    }
  }, []);


  return (
    <div className="container mt-3">
      <div className="text-center row">
        <h1 className="dashboard-title">My Dashboard</h1>
        <p className="dashboard-description">These are some of the platforms I enjoy using on a day to day basis. One day I think it will be both interesting and important to have it all in one place...</p>
      </div>
      <div className="row g-4">
        {/* <!-- Strava Activity Section --> */}
        <div className="col-md-4">
          <div className="card p-3 border bg-light mb-2 text-center">
            <h4 className="card-title">Recent Strava Activity</h4>
            <iframe title="Strava Activity" height="454" width="300"  allowTransparency={true} src="https://www.strava.com/athletes/116350830/latest-rides/8e1708b8f1444f97640d73fb8fddfddb3d7f5e30"></iframe>
          </div>

          {/* Yelp Review Section */}
          <div className='card p-3 border bg-light mb-2 text-center'>
            <h4 className="card-title">Recent Yelp Review</h4>
              <YelpReview />

          </div>
        </div> 

        {/* <!-- Letterboxd Reviews Section --> */}
        <div className="col-md-4">
          <div className="card p-3 border bg-light mb-2 text-center">
            <h4 className="card-title">My Letterboxd Reviews</h4>
            <div dangerouslySetInnerHTML={{ __html: letterboxdData }} />
          </div>
        </div>

        {/* <!-- Goodreads Widget Section --> */}
        <div className="col-md-4">
          <div className="card p-3 border bg-light mb-2 text-center">
            <h4 className="card-title">My Goodreads Updates</h4>
            <GoodreadsWidget/>
            {/* <span style="color: #382110">my read shelf:</span><br/><a href="https://www.goodreads.com/review/list/149274150?shelf=read" title="Sean Kearns's book recommendations, liked quotes, book clubs, book lists (read shelf)", rel="nofollow"><img border="0" alt="Sean Kearns's book recommendations, liked quotes, book clubs, book lists (read shelf)" src="https://s.gr-assets.com/images/badge/badge1.jpg"></a> */}
          </div>

          <div className="card p-3 border bg-light mb-2 text-center">
            <h4 className="card-title">My Current Spotify Daylist</h4>
            <iframe title = 'spotify-playlist' src="https://open.spotify.com/embed/playlist/37i9dQZF1EP6YuccBxUcC1?utm_source=generator&theme=0" width="100%" height="352" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>


        </div>
      </div>

    </div>

  );
};

export default App;
