import React from 'react';
import '../pages/styles.css';

interface ArticleEmbedProps {
  title: string;
  link: string;
  description: string;
  image: string;
}

const MediumArticlePreview: React.FC<ArticleEmbedProps> = ({ title, link, description, image }) => {
  return (
    <div className="article-embed card shadow-sm mb-4">
      <a href={link} target="_blank" rel="noopener noreferrer" className="article-link">
        <div className="article-image-wrapper">
          <img src={image} alt={title} className="article-image" />
        </div>
        <div className="article-content">
          <h3 className="article-title">{title}</h3>
          <p className="article-description">{description}</p>
          <span className="read-more">Read more →</span>
        </div>
      </a>
    </div>
  );
};

export default MediumArticlePreview;

