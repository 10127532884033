import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChartLine, faGamepad, faFolder } from '@fortawesome/free-solid-svg-icons';
import { FaLinkedin } from 'react-icons/fa';
import { AiFillGithub } from 'react-icons/ai';


const Header: React.FC = () => {
    return (
        <div className="d-flex justify-content-center mt-3">
            <nav className="navbar-pill text-center p-3">
                <div className="d-flex align-items-center">
                    {/* Name Link */}
                    {/* <Link
                        className="navbar-brand text-white me-3 mx-3"
                        to="/"
                        style={{ fontWeight: 'bold', fontSize: '24px' }}
                    >
                        Sean Kearns
                    </Link> */}

                    {/* Icon Links */}
                    <div className="d-flex align-items-center justify-content-center">
                        <Link className="nav-link icon-link mx-2" to="/">
                            <FontAwesomeIcon icon={faHome} />
                        </Link>
                        <Link className="nav-link icon-link mx-2" to="/portfolio">
                            <FontAwesomeIcon icon={faFolder} />
                        </Link>
                        <Link className="nav-link icon-link mx-2" to="/about">
                            <FontAwesomeIcon icon={faChartLine} />
                        </Link>
                        <Link className="nav-link icon-link mx-2" to="/gamer">
                            <FontAwesomeIcon icon={faGamepad} />
                        </Link>

                        {/* Social Icons */}
                        <div className="social-icons d-flex align-items-center justify-content-center ms-2">
                            <a
                                href="https://www.linkedin.com/in/sean-kearns-2b3599172/"
                                className="icon-link mx-2"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: '24px' }}
                            >
                                <FaLinkedin />
                            </a>
                            <a
                                href="https://github.com/sean-kearns"
                                className="icon-link mx-2"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: '24px' }}
                            >
                                <AiFillGithub />
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header;