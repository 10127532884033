// src/components/RunnerGame.tsx
import React, { useState, useEffect, useRef, useCallback  } from "react";
import '../pages/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Type Definitions
interface Obstacle {
  x: number;
  y: number;
}

// Constants for the game
const GAME_WIDTH = 800;
const GAME_HEIGHT = 200;
const OBSTACLE_WIDTH = 40;
const FIGURE_SIZE = 40;
const GRAVITY = 0.6;
const JUMP_STRENGTH = 15;

const RunnerGame: React.FC = () => {
  const [isJumping, setIsJumping] = useState<boolean>(false);
  const [figureY, setFigureY] = useState<number>(GAME_HEIGHT - FIGURE_SIZE);
  const [velocity, setVelocity] = useState<number>(0);
  const [obstacles, setObstacles] = useState<Obstacle[]>([]);
  const [distance, setDistance] = useState<number>(0);
  const [isGameOver, setIsGameOver] = useState<boolean>(false);

  const gameRef = useRef<HTMLDivElement>(null);

  // Handle jump
  const handleJump = useCallback((): void => {
    if (!isJumping && !isGameOver) {
      setIsJumping(true);
      setVelocity(-JUMP_STRENGTH); // Jump up by decreasing Y velocity
    }
  }, [isJumping, isGameOver]);

  // Game loop and event listeners
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === "Space") handleJump();
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleJump]);

  // Game loop logic
  useEffect(() => {
    if (isGameOver) return;

    const intervalId = setInterval(() => {
      // Physics for the figure
      setFigureY((prevY) => {
        let newY = prevY + velocity;
        if (newY >= GAME_HEIGHT - FIGURE_SIZE) {
          newY = GAME_HEIGHT - FIGURE_SIZE;
          setIsJumping(false);
          setVelocity(0);
        } else {
          setVelocity((v) => v + GRAVITY); // Apply gravity
        }
        return newY;
      });

      // Move obstacles and detect collision
      setObstacles((obs) => {
        const updatedObstacles = obs.map((ob) => ({ ...ob, x: ob.x - 5 }));
  
        // Adjusted hitbox sizes for better gameplay
        const hitboxPadding = 10; // Smaller hitbox for more leniency
  
        if (
          updatedObstacles.some(
            (ob) =>
              // Check if the obstacle is within horizontal range
              ob.x < 60 && ob.x + OBSTACLE_WIDTH > 20 &&
              // Check if the figure's hitbox and obstacle hitbox overlap vertically
              figureY >= GAME_HEIGHT - FIGURE_SIZE - OBSTACLE_WIDTH + hitboxPadding
          )
        ) {
          setIsGameOver(true); // Collision detected
          clearInterval(intervalId);
        }
        return updatedObstacles.filter((ob) => ob.x > -OBSTACLE_WIDTH);
      });

      // Increase distance
      setDistance((d) => d + 1);

      // Generate new obstacle
      if (Math.random() < 0.02) {
        setObstacles((obs) => [...obs, { x: GAME_WIDTH, y: GAME_HEIGHT - OBSTACLE_WIDTH }]);
      }
    }, 1000 / 60); // 60 FPS

    return () => clearInterval(intervalId);
  }, [figureY, velocity, isJumping, isGameOver]);

  const resetGame = (): void => {
    setFigureY(GAME_HEIGHT - FIGURE_SIZE);
    setVelocity(0);
    setObstacles([]);
    setDistance(0);
    setIsGameOver(false);
  };

  return (
    <div>
      <div className="game-container" ref={gameRef}>
        {isGameOver && <div className="game-over">Game Over!</div>}
        <div
          className="figure"
          style={{
            position: "absolute",
            width: `${FIGURE_SIZE}px`,
            height: `${FIGURE_SIZE}px`,
            backgroundColor: "blue",
            bottom: `${GAME_HEIGHT - figureY}px`,
            left: "20px",
          }}
        ></div>
        {obstacles.map((ob, index) => (
          <div
            key={index}
            className="obstacle"
            style={{
              position: "absolute",
              width: `${OBSTACLE_WIDTH}px`,
              height: `${OBSTACLE_WIDTH}px`,
              backgroundColor: "red",
              bottom: "0px",
              left: `${ob.x}px`,
            }}
          ></div>
        ))}
        <div className="distance">Distance: {distance} meters</div>
      </div>
      {isGameOver && (
        <div style={{'display':'flex', 'justifyContent': 'center', 'marginTop': '20px'}}>
          <button onClick={resetGame} className="reset-btn">
            Restart
          </button>
        </div>
      )}
    </div>
  );
};

export default RunnerGame;
