import React from 'react';
import { Star } from 'lucide-react';

const YelpReview = () => {
  const reviewData = {
    reviewerId: "Sean K.",
    reviewerUrl: "https://www.yelp.com/user_details?userid=dcxaXMYhxrymuF809_dcXQ",
    reviewUrl: "https://www.yelp.com/biz/amc-newcity-14-chicago-2?hrid=h_aKLaqVSTSWnd8f28fU5w",
    businessName: "AMC NEWCITY 14",
    businessUrl: "https://www.yelp.com/biz/FlMraRMXjfxt2ZeCFYP-Bg",
    rating: 4, // Assuming a 4-star rating, adjust as needed
    reviewText: "Great theater with comfortable seating and good sound system. The staff was friendly and the concessions were reasonably priced." // Example review text
  };

  return (
    <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-4">
      <div className="p-8">
        <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mb-1">
          Yelp Review
        </div>
        <a href={reviewData.businessUrl} className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">
          {reviewData.businessName}
        </a>
        <p className="mt-2 text-gray-500">
          Reviewed by <a href={reviewData.reviewerUrl} className="text-indigo-500 hover:underline">{reviewData.reviewerId}</a>
        </p>
        <div className="flex items-center mt-2">
          {[...Array(5)].map((_, i) => (
            <Star key={i} className={`h-5 w-5 ${i < reviewData.rating ? 'text-yellow-400' : 'text-gray-300'}`} />
          ))}
        </div>
        <p className="mt-2 text-gray-500">{reviewData.reviewText}</p>
        <a href={reviewData.reviewUrl} className="mt-4 inline-block bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600 transition duration-300">
          Read full review on Yelp
        </a>
      </div>
    </div>
  );
};

export default YelpReview;