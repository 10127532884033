import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../styles.css';

const ChicagoFireProject: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//cdn.credly.com/assets/utilities/embed.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="container mt-5">
      {/* Page Header */}
      <div className="text-center mb-5">
        <h1 className="display-4 fw-bold">Chicago Fire FC: A Data Engineering Journey</h1>
        <p className="lead">
          How we built a centralized, scalable data stack using Snowflake, Prefect, and AWS to empower real-time analytics.
        </p>
      </div>

      {/* Introduction Section */}
      <section className="mb-5">
        <p className="fs-5">
          When Chicago Fire FC approached us, their data landscape was fragmented. Marketing, ticket sales, and corporate data were siloed, making it difficult to gather insights in real-time. Our challenge was to centralize all data streams, enabling real-time analytics that would drive revenue and streamline operations.
        </p>
        <blockquote className="blockquote text-center my-4">
          <p className="mb-0 fst-italic">
            "We needed a solution that could transform raw data into actionable insights, and we needed it fast."
          </p>
        </blockquote>
      </section>

      {/* Image: Project Background */}
      <div className="text-center mb-5">
        <img
          src="chicago_fire_teck_stack.jpg"
          alt="Chicago Fire Tech Stack Diagram"
          className="img-fluid"
          style={{
            maxWidth: '600px',
            height: 'auto',
            border: '3px solid #007bff',
            borderRadius: '15px',
            marginBottom: '20px',
          }}
        />
      </div>

      {/* Project Background Story */}
      <section className="mb-5">
        <h2 className="h3 mb-4">Understanding the Problem</h2>
        <p>
          The starting point was recognizing the limitations of their existing setup. Departments were dependent on spreadsheets, and external vendors managed marketing dashboards, which incurred significant costs. The reporting lag hindered decision-making.
        </p>
        <ul className="fs-5">
          <li>Marketing data (email, social media, ads) needed centralization.</li>
          <li>Ticketing reports took days to generate, impacting revenue strategies.</li>
          <li>Corporate partners struggled with inconsistent CRM workflows.</li>
        </ul>
      </section>

      {/* Technical Implementation */}
      <section className="mb-5">
        <h2 className="h3 mb-4">The Solution: Building In-House Data Infrastructure</h2>
        <p>
          The answer lay in developing a custom solution using a combination of Snowflake, Prefect, and AWS Fargate. By leveraging open-source tools, we were able to reduce dependency on costly third-party services like Fivetran, while increasing control and flexibility.
        </p>

        <div className="text-center my-4">
          <img
            src="prefect_architecture.png"
            alt="Prefect on AWS Architecture"
            className="img-fluid"
            style={{
              maxWidth: '600px',
              height: 'auto',
              borderRadius: '15px',
              border: '3px solid #28a745',
              marginBottom: '20px',
            }}
          />
        </div>
        <p>
          We orchestrated our data pipelines using Prefect on AWS Fargate, which allowed us to scale efficiently. Data was ingested into Snowflake, transformed, and then served via reverse ETL back to platforms like Salesforce for actionable insights.
        </p>
      </section>

      {/* Challenges & Solutions */}
      <section className="mb-5">
        <h2 className="h3 mb-4">Overcoming Challenges</h2>
        <p>
          Transitioning away from external vendors wasn’t easy. It required us to build trust internally and prove that an in-house solution could deliver better results. Here’s how we tackled some key challenges:
        </p>
        <ul className="fs-5">
          <li>
            <strong>High Costs:</strong> Fivetran’s pricing escalated quickly. We shifted to open-source Prefect, cutting costs by 40%.
          </li>
          <li>
            <strong>Data Silos:</strong> Marketing, ticketing, and CRM data were previously isolated. Centralizing this in Snowflake enabled real-time dashboards, reducing reporting delays from days to hours.
          </li>
          <li>
            <strong>Customization:</strong> Prefect allowed us to build custom ETL pipelines, increasing control over data ingestion.
          </li>
        </ul>
      </section>

      {/* Results & Impact */}
      <section className="mb-5">
        <h2 className="h3 mb-4">Results</h2>
        <p className="fs-5">
          By centralizing data and automating workflows, we achieved significant improvements:
        </p>
        <ul className="fs-5">
          <li>Reduced data processing costs by 40%.</li>
          <li>Enabled marketing teams to monitor campaigns in real-time, boosting engagement by 25%.</li>
          <li>Increased reporting speed, reducing data lag from days to hours.</li>
        </ul>
      </section>

      {/* Future Plans */}
      <section className="mb-5">
        <h2 className="h3 mb-4">Looking Ahead</h2>
        <p className="fs-5">
          With our data infrastructure now in place, we plan to explore predictive analytics using machine learning models. This will help optimize ticket sales and improve engagement strategies, driving further growth for Chicago Fire FC.
        </p>
      </section>

      {/* Conclusion */}
      <section className="text-center mt-5">
        <blockquote className="blockquote">
          <p className="mb-0 fst-italic">
            “Data is not just about numbers; it's about empowering the organization to make smarter, faster decisions.”
          </p>
        </blockquote>
        <a href="/" className="btn btn-primary mt-4">Back to Portfolio</a>
      </section>
    </div>
  );
};

export default ChicagoFireProject;
