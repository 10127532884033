
import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "keen-slider/keen-slider.min.css"
import './styles.css';
import { useKeenSlider } from "keen-slider/react"
import MediumArticlePreview from '../components/MediumArticle.tsx';

interface Client {
    id: number;
    content: string;
    image: string;
    website: string;
    description: string;
    technologies?: string[];
    languages?: string[];
    sources?: string[];
}

const clients: Client[] = [
    {
        id: 1,
        content: "Chicago Fire",
        image: "chi-logo.png",
        website: "https://www.chicagofirefc.com/",
        description: "Data engineering solutions for Chicago Fire FC.",
        technologies:['snowflake', 'salesforce', 'ticketmaster', 'aws_s3', 'aws_lambda', 'aws_ec2', 'aws_cloudwatch', 'aws_cloudfront', 'aws_route53', 'postgres', 'tableau', 'sigma', 'okta', 'jira', 'github', 'prefect', 'openai'],
        languages: ['python', 'r', 'sql', 'javascript', 'react', 'dbt'],
    },
    {
        id: 2,
        content: "Boost Sports",
        image: "boost_logo.gif",
        website: "https://boostsport.ai/",
        description: "Sports data platform engineering and infrastructure.",
        technologies:['snowflake', 'aws_s3', 'aws_lambda', 'aws_ec2', 'aws_cloudwatch', 'jira', 'fivetran'],
        languages: ['python', 'sql', 'terraform', 'javascript', 'dbt'],
    },
    {
        id: 3,
        content: "NYT Leaderboards",
        image: "leaderboard_logo.png",
        website: "https://www.nytleaderboards.com/",
        description: "Leaderboard website for NYT games using Django and AWS.",
        technologies:['django', 'aws_s3', 'aws_ec2', 'aws_cloudwatch', 'github', 'godaddy'],
        languages: ['python'],
    },
];

//from that list, combine into one list 
const allTechnologies: string[] = [];
const allLanguages: string[] = [];
const allSources: string[] = [];

clients.forEach(client => {
    allTechnologies.push(...(client.technologies || []));
    allLanguages.push(...(client.languages || []));
    allSources.push(...(client.sources || []));
});

//and deduplicate them 
const distinctTechnologies = [...new Set(allTechnologies)];
const distinctLanguages = [...new Set(allLanguages)];
const distinctSources = [...new Set(allSources)];

//randomize their order 
const techBubbles = [
    ...distinctTechnologies.map(tech => ({ type: 'technology', name: tech })),
    ...distinctLanguages.map(lang => ({ type: 'language', name: lang })),
    ...distinctSources.map(source => ({ type: 'source', name: source })),
].sort(() => Math.random() - 0.5);;

//value for the speed of the bubbles moving
const animation = { duration: 30000, easing: (t: number) => t }; // Animation settings


const PortfolioPage: React.FC = () => {

    const [sliderRefSmall] = useKeenSlider<HTMLDivElement>({
        loop: true,
        renderMode: "performance",
        mode: "free",
        drag: true,
        breakpoints: {
            "(min-width: 300px)": { slides: { perView: 2, spacing: .5 } },
            "(min-width: 400px)": { slides: { perView: 3, spacing: 1 } },
            "(min-width: 600px)": { slides: { perView: 5, spacing: 1 } },
            "(min-width: 1000px)": { slides: { perView: 8, spacing: 1 } },
            "(min-width: 1400px)": { slides: { perView: 10, spacing: 1 } },
        },
        slides: {
            origin: 'center',
            perView: 12,
            spacing: 1,
        },
        created(s) {
            s.moveToIdx(-15, true, animation); // Start auto-scroll on creation for small slider
        },
        updated(s) {
            s.moveToIdx(s.track.details.abs - 15, true, animation); // Continue auto-scroll on update
        },
        animationEnded(s) {
            s.moveToIdx(s.track.details.abs - 15, true, animation); // Loop the animation
        },
    });

    
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//cdn.credly.com/assets/utilities/embed.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);


    return (
        <div className="container mt-3">
            {/* Portfolio Header */}
            <div className="text-center row">
                <h1 className="dashboard-title">Portfolio</h1>
                <p className="dashboard-description">Explore my work across data engineering, sports analytics, and tech consulting.</p>
            </div>

            {/* Featured Clients Section */}
            <section className="row mt-2">
                <h2 className="mb-4 text-center">Featured Clients</h2>
                {clients.map(client => {
                    const isGif = client.image.endsWith('.gif');
                    return (
                        <div key={client.id} className="col-md-4">
                            <a href={client.website} className="card p-3 text-center border bg-light mb-2 text-decoration-none">
                                <img src={client.image} alt={client.content} 
                                    className={isGif ? 'invert-gif' : 'card-img-top'}
                                 style={{ height: '150px', objectFit: 'contain' }} />
                                <div className="card-body">
                                    <h5 className="card-title">{client.content}</h5>
                                    <p className="card-text">{client.description}</p>
                                </div>
                            </a>
                        </div>
                    );
                })}
            </section>

            {/* Skills & Technologies */}
            <section className="container my-4">
                <h2 className="mb-4 text-center">Tools I've Used</h2>
                <div ref={sliderRefSmall} className="keen-slider tech-carousel">
                    {techBubbles.map((item, index) => (
                        <div className="keen-slider__slide tech-slide" key={index}>
                            <div className={`tech-circle ${item.type}`}>
                                <span></span>
                                <img src={`externals/${item.name}.png`}  alt={`${item.name} logo`} style={{ width: 'auto', height: '50px' }} />
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Project & Experience Details */}
            <section className="row g-4 mt-5">
                <h2 className="mb-4 text-center">Highlighted Projects & Experience</h2>

                {/* Chicago Fire Project */}
                <div className="col-md-6">
                    <div className="card p-3 border bg-light mb-4">
                    <div className="d-flex align-items-center">
                        <img src="chi-logo.png" alt="Chicago Fire FC" style={{ width: '80px', marginRight: '20px', borderRadius: '10px' }} />
                        <div>
                        <h3>Full Stack Data Engineer</h3>
                        <p>Chicago Fire FC</p>
                        <p>Feb 2024 - Present</p>
                        <ul>
                            <li>Built a front-office portal using React for the frontend and Django for the backend.</li>
                            <li>Developed a middleware API to connect the portal to Snowflake.</li>
                            <li>Served the portal using AWS CloudWatch, Route 53, S3, and an EC2 instance with Nginx for the backend.</li>
                            <li>Fine-tuned OpenAI models for email generation, article summarization, and text-to-SQL conversion.</li>
                        </ul>
                        </div>
                    </div>

                    {/* Embed Related Medium Articles */}
                    <div className="mt-4">
                        <MediumArticlePreview
                            title="Building a Sovereign Tech Stack in Sports"
                            link="https://medium.com/@seanpjk/building-a-sovereign-tech-stack-in-sports-f7b0f609e8fb"
                            description="This article goes more in depth on what solutions we use in our tech stack, why we went about choosing them, and some of the benefits we obtained in doing so. It is always an ongoing process, but this is how we arrived at the current state."
                            image="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*b4Wq_gcjvdZXNJWeE6ksNg.png" />
                    </div>
                    </div>
                </div>

                {/* Boost Sports Project */}
                <div className="col-md-6">
                    <div className="card p-3 border bg-light mb-4">
                    <div className="d-flex align-items-center">
                        <img src="boost_logo.gif" alt="Boost Sports" style={{ width: '80px', marginRight: '20px', borderRadius: '10px', filter: 'invert(1)' }} />
                        <div>
                        <h3>Engineering Consultant</h3>
                        <p>Boost Sports</p>
                        <p>May 2024 - Present</p>
                        <ul>
                            <li>Engineered data pipelines and collaborated on front-end enhancements.</li>
                            <li>Developed dynamic AWS and Snowflake pipelines for onboarding.</li>
                            <li>Implemented Lambda functions for automated data cleansing.</li>
                        </ul>
                        </div>
                    </div>

                    </div>
                </div>

                                {/* Dyer Brothers Sealcoating Project */}
                                <div className="col-md-6">
                    <div className="card p-3 border bg-light mb-4">
                    <div className="d-flex align-items-center">
                        <img src="dyer_bros_no_bg.png" alt="Dyer Brothers Sealcoating" style={{ width: '80px', marginRight: '20px', borderRadius: '10px' }} />
                        <div>
                        <h3>Engineering Consultant</h3>
                        <p>Dyer Brothers Sealcoating</p>
                        <p>Jul 2023 - Feb 2024</p>
                        <ul>
                            <li>Hubspot CRM Migration from Google Sheets.</li>
                            <li>Utilized AWS Pinpoint for cost-effective phone number analysis.</li>
                        </ul>
                        </div>
                    </div>

                    </div>
                </div>

                {/* NYT Leaderboards Project */}
                <div className="col-md-6">
                    <div className="card p-3 border bg-light mb-4">
                    <div className="d-flex align-items-center">
                        <img src="leaderboard_logo.png" alt="NYT Leaderboards" style={{ width: '80px', marginRight: '20px', borderRadius: '10px' }} />
                        <div>
                        <h3>NYT Connections Leaderboard</h3>
                        <p>nytleaderboards.com</p>
                        <p>Feb 2024 - Present</p>
                        <ul>
                            <li>Built a leaderboard site for NYT Connections scores.</li>
                            <li>Implemented OAuth 2.0 and Google Analytics.</li>
                            <li>Deployed on AWS EC2 with Nginx and Gunicorn.</li>
                        </ul>
                        </div>
                    </div>

                    {/* Embed Related Medium Articles */}
                    <div className="mt-4">
                        <MediumArticlePreview
                            title="How Staying Up All Night Coding Can Make Your Friends Happy"
                            link="https://medium.com/@seanpjk/how-staying-up-all-night-coding-can-make-your-friends-happy-a19ca0399c39"
                            description="This article tells the story of why we needed to make a leaderboard for our friends, and how we went about doing so."
                            image="https://miro.medium.com/v2/resize:fit:1400/format:webp/0*hYvkvGYEyZ3ySmoc" />
                    </div>
                    </div>
                </div>


            </section>
        </div>
    );
};

export default PortfolioPage;


