import React, { useState, useEffect } from 'react';
import { Typewriter } from 'react-simple-typewriter';
import { Drawer, FloatButton } from "antd";
import { MdOutlineWavingHand } from "react-icons/md";
import ContactUs from '../components/contact_form.tsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

const FrontPage: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [showDataEngineer, setShowDataEngineer] = useState(false);

    // Delay for the "Data Engineer" typing effect
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowDataEngineer(true);
        }, 3000); // 2-second delay

        return () => clearTimeout(timer);
    }, []);


    return (
        <div style={{
            position: 'relative',
            height: '60vh',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            fontFamily: "'Lexend Peta', sans-serif",
            textAlign: 'center'
        }}>
            <h1 style={{
                fontSize: '4rem',
                color: '#FFBD59',
                textShadow: '0 0 5px rgba(255, 189, 89, 0.7), 0 0 10px rgba(255, 189, 89, 0.5)',
                marginBottom: '0.5rem'
            }}>
                <Typewriter
                    words={["Sean Kearns"]}
                    loop={1}
                    typeSpeed={150}
                    deleteSpeed={65}
                    delaySpeed={700}
                    onLoopDone={() => console.log('Typing complete!')}
                />
            </h1>
            {showDataEngineer && (
                <h2 style={{
                    fontSize: '1.5rem',
                    color: 'grey',
                    textShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                }}>
                    <Typewriter
                        words={["Data Engineer", "Software Developer", "Coffee Enthusiast", "Nerd"]}
                        loop={0}
                        typeSpeed={150}
                        deleteSpeed={65}
                        delaySpeed={700}
                        // onLoopDone={handleLoopDone}
                    />
                </h2>
            )}

            <FloatButton
                icon={<MdOutlineWavingHand />}
                description="Say Hey!"
                onClick={() => setOpen(true)}
                badge={{ count: 1 }}
                type="default"
                style={{ width: 80, height: 80, position: 'fixed', bottom: 24, right: 24 }}
            />
            <Drawer
                title="Contact Me"
                placement="right"
                onClose={() => setOpen(false)}
                open={open}
                size="large"
            >
                <ContactUs setOpen={setOpen} />
            </Drawer>
        </div>
    );
};

export default FrontPage;
