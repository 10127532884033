import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import RunnerGame from "../components/RunnerGame.tsx";
// import GlobeComponent from "../components/GlobeComponent.tsx";

const GamePage: React.FC = () => {
  return (
    <div className="container mt-3">
        {/* Corrected row and col usage */}
        <div className="row">
            <div className="col text-center">
                <h1 className="dashboard-title">Let's Run!!</h1>
            </div>
        </div>

        {/* game box */}
        <div className="row">
            <RunnerGame />
        </div>

        {/* <div className="row">
            <GlobeComponent />
        </div> */}
    </div>
  );
};

export default GamePage;
