import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const GA4PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location) {
      console.error("Location is undefined. Router might not be correctly set up.");
    } else if (!location.pathname) {
      console.error("Pathname is undefined. This might indicate an issue with Router context.");
    } else {
      console.log("Tracking page view:", location.pathname);
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location]);

  return null; // This component doesn't render anything visible
};

export default GA4PageViewTracker;

