import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer: React.FC = () => {
    return (
        <footer className="bg-dark text-white text-center p-3" style={{ width: '100%' }}>
            <p>© 2024 SKearns LLC. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
