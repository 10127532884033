import React from 'react';
// import '../pages/styles.css'; // Assuming you extract CSS into a separate file

const GoodreadsWidget = () => {
  return (
    <div id="gr_updates_widget">
      <iframe
        sandbox="allow-scripts allow-same-origin"
        id="the_iframe"
        width="300"
        height="330"
        src="https://www.goodreads.com/widgets/user_update_widget?height=500&num_updates=30&user=149274150&width=300"
        // src="https://www.goodreads.com/review/custom_widget/149274150.My%20Read%20Books?cover_position=left&cover_size=medium&num_books=5&order=d&shelf=read&show_author=1&show_cover=1&show_rating=1&show_review=1&show_tags=1&show_title=1&sort=date_added&widget_bg_color=FFFFFF&widget_bg_transparent=&widget_border_width=1&widget_id=1723474421&widget_text_color=000000&widget_title_size=medium&widget_width=medium"

        // frameBorder="0"
        title="Goodreads Updates"
      ></iframe>
      <div id="gr_footer">
        <a href="https://www.goodreads.com/review/list/149274150?shelf=read">
          <img
            alt="Goodreads: Book reviews, recommendations, and discussion"
            rel="nofollow"
            src="https://s.gr-assets.com/images/layout/goodreads_logo_140.png"
          />
        </a>
      </div>
    </div>
  );
};

export default GoodreadsWidget;



