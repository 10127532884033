import React from 'react';
import { Route, Routes } from 'react-router-dom';

// import HomePage from './pages/HomePage.tsx';
import PortfolioPage from './pages/PortfolioPage.tsx';
import AboutPage from './pages/AboutPage.tsx';
import RunnerGame from './pages/GamePage.tsx';

import ChicagoFireProject from './pages/projects/ChicagoFire_DataEngineering.tsx';
import FrontPage from './pages/SampleHomePage.tsx';

import Header from './components/header.tsx';
import Footer from './components/footer.tsx';

import ReactGA from "react-ga4";
import GA4PageViewTracker from './components/ga4PageViewTracker.tsx';

import './App.css';

// Initialize Google Analytics
ReactGA.initialize("G-W07R4DG49H");

function App() {
    return (
        <div className="app-container">
            <Header />
            <main className="content">
                <Routes>
                    <Route path="/" element={<FrontPage />} />
                    <Route path="/portfolio" element={<PortfolioPage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/gamer" element={<RunnerGame />} />

                    <Route path="/chicagofire_1" element={<ChicagoFireProject />} />
                    {/* <Route path='/sample' element={<FrontPage />} /> */}
                </Routes>
            </main>
            <Footer />
            <GA4PageViewTracker />
        </div>
    );
}

export default App;

